<template>
  <v-card class="shadow-sm mb-10 rounded-xl"
          style="cursor: pointer"
  >
    <v-card-title
      @click="fetchDetails"
      :ripple="false"
      class="d-flex align-center">
      <span class="font-rubik font-weight-bold primary px-3 rounded-lg white--text"
            style="font-size: 17px">{{ item.university_acronym }}</span>


      <span class="font-rubik font-weight-medium d-inline-block text-truncate ml-3"
            style="font-size: 21px; flex: 1"
      >
        {{ item.career_name }}
      </span>


      <span class="font-rubik font-weight-bold" style="font-size: 19px">{{ item.grade.toFixed(3) }}</span>
      <v-btn
        v-if="!$vuetify.breakpoint.smAndDown"
        icon>
        <v-icon>{{ showPonderacions ? mdiChevronUp : mdiChevronDown }}</v-icon>
      </v-btn>
    </v-card-title>

    <v-progress-linear v-if="loading" indeterminate></v-progress-linear>

    <v-sheet v-if="showPonderacions"
             transition="scroll-y-transition" autobuy
             color="rgb(246 247 251)">
      <v-card-text>
        <span class="font-rubik">Assignatures que ponderen:</span>
        <v-chip-group
          :column="true"
          class="mb-5"
        >
          <v-chip v-for="(tag, idx) in details.ponderacions"
                  :key="idx+'_pond'"
                  color="#4BA0EC"
                  dark outlined
                  class="mr-1 px-4 py-2"
                  style="border-width: 3px"
          >
            <span class="font-weight-bold font-rubik"
                  style="color: rgba(2,32,49,0.83); font-size: 17px"
            >
              {{ tag.subject_name }} ({{ tag.ponderacio }})</span>
          </v-chip>
        </v-chip-group>

        <canvas
          v-if="details && details.notes && item"
          :id="`canvas_${item.career_id}`"
          style="width: 100%; height: 300px"
        ></canvas>
      </v-card-text>


    </v-sheet>
  </v-card>
</template>

<script>
import {mdiChevronUp} from '/src/assets/mdi.json'
import {mdiChevronDown} from '/src/assets/mdi.json'

export default {
  name: "PonderacionsCard",
  props: {
    item: Object,
    public: String
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  async mounted() {
    await this.fetchDetails()
    this.drawChart()
  },
  data() {
    return {
      mdiChevronUp: mdiChevronUp,
      mdiChevronDown: mdiChevronDown,
      showPonderacions: false,
      loading: false,
      details: null,
    }
  },
  methods: {
    async fetchDetails() {
      if (!this.showPonderacions) {
        try {
          this.loading = true
          const {data} = await this.axios.get(`/ponderacions/${this.item.career_id}`);
          if (data) {
            this.showPonderacions = true;
            this.details = data
          }
        } finally {
          this.loading = false
          this.drawChart()
        }
      } else this.showPonderacions = false;
    },
    getGrades() {
      return this.details.notes.map(note => parseFloat(note.notes[this.public]))
    },
    getYearsOfGrades() {
      return this.details.notes.map(note => note.any.toString())
    },
    drawChart() {
      if (this.details && this.details.notes)
        setTimeout(() => {
          let datas = this.getGrades()
          let labels = this.getYearsOfGrades()
          if (this.$vuetify.breakpoint.smAndDown) {
            datas = datas.slice(-4)
            labels = labels.slice(-4)
          }
          const minimum = Math.min(...datas)
          const maximum = Math.max(...datas)

          const c = document.getElementById(`canvas_${this.item.career_id}`);
          const width = c.offsetWidth
          const height = c.offsetHeight
          const padding = 50

          c.width = width
          c.height = height

          const ctx = c.getContext("2d");
          ctx.globalCompositeOperation = 'destination-over';
          ctx.textAlign = "center";


          // Line style
          ctx.lineWidth = 5;
          ctx.lineCap = 'round';
          ctx.lineJoin = 'round'

          ctx.beginPath();
          datas.forEach((data, i) => {

            const lasty = (1 - (((data - minimum)) / (maximum - minimum))) * (height - padding * 2) + padding;
            const lastx = Math.floor((width - padding * 2) / (datas.length - 1)) * i + padding;
            // if (i === 0)
            //   ctx.moveTo(lastx, lasty);
            ctx.lineTo(lastx, lasty);
            const dotSize = 4
            ctx.arc(lastx, lasty, dotSize, 0, 2 * Math.PI);

            // Value's style
            ctx.font = "bold 20px Rubik";
            ctx.letterSpacing = "1px";
            ctx.fillStyle = "rgba(2,32,49,0.83)";
            ctx.fillText(data.toFixed(3), lastx + 15, lasty - 20);

            // Labels style
            ctx.font = "20px Rubik";
            ctx.letterSpacing = "1px";
            ctx.fillText(labels[i], lastx, height);

            // ctx.fillText(lasty, lastx, lasty + 10);
          });


          ctx.strokeStyle = "#4BA0EC";
          ctx.stroke()

          // Watermark
          ctx.font = this.$vuetify.breakpoint.smAndDown ? "bold 23px Rubik" : "bold 70px Rubik";
          ctx.letterSpacing = this.$vuetify.breakpoint.smAndDown ? "1px" : "-4px";
          ctx.fillStyle = "rgba(3,26,46,0.07)";
          ctx.fillText("examenselectivitat.cat", width / 2, height / 2);

        }, 200)


    },
    onResize(e) {
      // your code for handling resize...
      if (this.details && this.details.notes) {
        this.drawChart()
      }
    }
  },


}
</script>

<style scoped>

</style>
