<template>
  <div class="d-flex autocomplete-search-ponderacions">
    <v-select
      label="Provinència"
      v-model="public"
      :items="publics"
      single-line
      style="border-right: 1px solid #DDDDDD; max-width: 150px"
      item-text="text"
      item-value="value"
      class="rounded-r-0 provinencia-selector"
      solo
      dense
      hide-details
      no-filter
      height="62"
      flat
    ></v-select>
    <div class="d-flex" style="position: relative; flex: 1">
      <v-autocomplete
        v-model="model"
        @input="addQueryParameters"
        :search-input.sync="search"
        :items="items"
        class="font-weight-medium rounded-l-0"
        label="Cerca carreres.."
        :menu-props="{
              height: '100%',
              maxHeight: '100%',
              width: '100%'
            }"
        no-data-text="No hem trobat resultats"
        hide-no-data
        :append-icon="mdiMagnify"
        hide-details
        no-filter
        single-line
        height="62"
        style="flex: 1"
        flat
        solo
      >
      </v-autocomplete>
      <v-btn
        @click="addQueryParameters"
        style="position: absolute; right: 0; top: 6px"
             depressed color="primary"
             class="pa-2 mr-2" height="50">
        <v-icon size="23">{{mdiMagnify}}</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import {mdiMagnify} from '/src/assets/mdi.json'

export default {
  name: "PonderacionsSearchBar",
  props: {
    value: String,
    publicProp: {
      type: String
    },
  },
  mounted() {
    if (this.publicProp) {
      this.public = this.publicProp
    } else if (this.$route.params.public) {
      this.public = this.$route.params.public;
    }
  },
  data() {
    return {
      renderKey: 0,
      searchQuery: null,
      mdiMagnify: mdiMagnify,
      items: [],
      model: '',
      public: 'grade_pau',
      publics: [
        {
          value: 'grade_pau',
          text: 'PAU i FP'
        },
        {
          value: 'grade_uni',
          text: 'Titulats universitaris'
        },
        {
          value: 'grade_25',
          text: 'Més grans de 25 anys'
        }

      ]
    }
  },
  computed: {
    search: {
      get() {
        return this.searchQuery
      },
      set(searchInput) {
        if (this.searchQuery !== searchInput) {
          this.searchQuery = searchInput;
          this.fetchSearch()
        }
      }
    }
  },
  methods: {
    async fetchSearch() {
      if (this.searchQuery && this.searchQuery.length > 1) {
        const path = `/ponderacions-search/${this.searchQuery}`;
        const {data} = await this.axios.get(path);
        this.items = data;
      }
    },
    addQueryParameters() {
      if (this.searchQuery && this.public)
        this.$router.push({
          path: `/notes-de-tall`, query: {
            public: this.public,
            query: this.model
          }
        })
    }
  },
  watch: {
    public() {
      this.addQueryParameters();
    },
    '$route.query': {
      immediate: true,
      async handler(val) {
        if (val && val.query && val.public) {
          this.public = val.public;
          await this.fetchSearch();
          this.model = val.query;
          this.items.push(val.query)
        }
      }
    },
  }
}
</script>

<style scoped>
.autocomplete-search-ponderacions {
  border: 1px solid #DDDDDD !important;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 8%), 0px 4px 12px rgb(0 0 0 / 5%) !important;
}

/*.provinencia-selector >>> .v-input__slot {*/
/*  min-width: 200px;*/
/*}*/

/*.provinencia-selector >>> input {*/
/*  display: none;*/
/*}*/

</style>
