<template>
  <div>
    <!-------------------------------------->
    <!-- ORDER & FILTER                   -->
    <!-------------------------------------->
    <div class="d-flex mb-6"
         :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : 'align-center'"
    >
      <div
        class="d-flex align-center">
        <span class="mr-2 body-2 blue-grey--text">Ordenar per</span>
        <v-select
          v-model="order_id"
          :items="orders"
          @change="getResults"
          single-line
          item-text="text"
          item-value="id"
          style="max-width: fit-content"
          outlined
          dense
          hide-details
          flat
        ></v-select>
      </div>
      <v-spacer v-if="!$vuetify.breakpoint.smAndDown"></v-spacer>
      <div
        class="d-flex align-center">
        <span class="mr-2 body-2 blue-grey--text">Filtrar per universitat</span>
        <v-select
          v-model="universitat"
          :items="universities"
          @change="getResults"
          single-line
          item-text="university_acronym"
          item-value="university_acronym"
          style="max-width: fit-content"
          dense
          outlined
          hide-details
          flat
        ></v-select>
      </div>

    </div>

    <!-------------------------------------->
    <!-- CARDS RESULTS                    -->
    <!-------------------------------------->
    <div v-if="loading" style="width: 100%">
      <pulse-loader :loading="loading" style="margin: auto; max-width: 60px; min-height: 400px"></pulse-loader>
    </div>
    <template v-else>
      <div v-for="(item, idx) in items" :key="idx + '_card'">
        <ponderacions-card
          :item="item"
          :public="public"
        ></ponderacions-card>
      </div>

      <div v-if="items.length === 0" class="text-center py-12">
        <span class="tit">No s'ha trobat cap carrera amb aquestes característiques.</span>
      </div>
    </template>

    <v-pagination
      v-if="items && items.length > 0"
      v-model="page"
      @input="getResults"
      color="blue accent-1"
      :length="pageLength"
      class="mb-12 pagination"
      :prev-icon="mdiMenuLeft"
      :next-icon="mdiMenuRight"
    ></v-pagination>
  </div>
</template>

<script>
import {mdiMenuLeft} from '/src/assets/mdi.json'
import {mdiMenuRight} from '/src/assets/mdi.json'

import pulseLoader from '../../components/VLoader.vue'
import PonderacionsCard from "./PonderacionsCard";

export default {
  name: 'PonderacionsResult',
  components: {
    PonderacionsCard,
    pulseLoader
  },
  data() {
    return {
      mdiMenuLeft: mdiMenuLeft,
      mdiMenuRight: mdiMenuRight,
      order_id: 0,
      orders: [
        {
          text: 'Relacionats',
          id: 0
        },
        {
          text: 'Nota de tall',
          id: 1
        }
      ],
      loading: false,
      length: null,
      page: 1,
      public: 'grade_pau',
      searchQuery: null,
      items: null,
      subjects: null,
      universitat: 'Totes les universitats',
      universities: null,
      model: -1,
    }
  },
  async mounted() {

    let {data} = await this.axios.get('/universities')
    data.unshift({
      university_id: -1,
      university_acronym: 'Totes les universitats'
    })
    this.universities = data;

  },
  methods: {
    async getResults() {
      try {
        this.loading = true
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0

        const {data} = await this.axios.get(`/notes-de-tall/${this.searchQuery}/${this.order_id}/${this.universitat}/${this.public}/${this.offset}`);
        this.items = data.resultat
        this.length = data.length
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler(val) {
        if (val.query && val.public) {
          this.searchQuery = val.query.toString();
          this.public = val.public.toString();
          this.getResults();
        }
      }
    },
    searchQuery(val) {
      if (val.length === 0) {
        this.items = null
      }
    }
  },
  computed: {
    pageLength() {
      return Math.ceil(this.length / 7)
    },
    offset() {
      return 7 * (this.page - 1)
    },
  }
}
</script>

<style scoped>

.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 70px;
}

</style>
