<template>
  <div>
    <div>

      <v-sheet color="#E9F4FC" style="padding-bottom: 50px">

        <v-container class="py-6 px-3 "
                     style="max-width: 1100px !important;"
                     :class="$vuetify.breakpoint.xs ? 'px-3' : ''"
        >
          <v-col cols="12" md="9">
            <h1 class="tit font-weight-bold  mt-3 mb-4"
                style="font-size: 40px; color: #252549;"
            >
              Cercador de Notes de tall
            </h1>


            <v-btn
              v-if="$root.currentUser && $root.currentUser.isAdmin()"
              block
              color="primary"
              dark
              class="text-none font-weight-bold"
              @click="addCSVDialog=true">
              Afegir CSV
            </v-btn>


            <div>
              <p class="blue-grey--text text--darken-3 pb-2"
                 style="font-size: 16px !important;"
              >
                Cerca qualsevol grau universitari per saber quines són les assignatures que t'has de presentar
                a la Selectivitat i la nota de tall que exigeixen en cada universitat pública de Catalunya.
              </p>
            </div>

          </v-col>
        </v-container>

      </v-sheet>

      <div class="px-6 mx-auto" style="max-width: 1100px">
        <ponderacions-search-bar v-model="searchQuery" style="margin-top: -30px"></ponderacions-search-bar>
      </div>
    </div>
    <!-------------------------------------->
    <!-- TOP CAREERS                      -->
    <!-------------------------------------->
    <v-container class="pt-6 px-3"
                 style="max-width: 1100px !important; padding-bottom: 200px"
                 :class="$vuetify.breakpoint.xs ? 'px-3' : ''"
    >


      <!-------------------------------------->
      <!-- TOP                              -->
      <!-------------------------------------->
      <v-row no-gutters class="pa-3">
        <v-col cols="12">
          <!-------------------------------------->
          <!-- POPULAR CAREERS                  -->
          <!-------------------------------------->
          <ponderacions-top v-if="!$route.query.query"></ponderacions-top>
          <ponderacions-result v-else></ponderacions-result>
        </v-col>
        <!-------------------------------------->
        <!-- Right Adsense                    -->
        <!-------------------------------------->
        <!--        <v-col v-if="!$vuetify.breakpoint.smAndDown" cols="3">-->
        <!--          <div class="sticky">-->
        <!--            <Adsense-->
        <!--              class="adsbygoogle mt-6"-->
        <!--              v-if="true"-->

        <!--              style="display:block; max-width: 300px"-->
        <!--              data-ad-client="ca-pub-8602467256798312"-->
        <!--              data-ad-slot="8444206542"-->
        <!--              data-ad-format="auto"-->
        <!--              data-full-width-responsive="true"-->
        <!--            ></Adsense>-->
        <!--          </div>-->
        <!--        </v-col>-->
      </v-row>
    </v-container>


    <!-------------------------------------->
    <!-- Add CSV Dialog                   -->
    <!-------------------------------------->
    <v-dialog v-if="$root.currentUser.isAdmin()" v-model="addCSVDialog" max-width="1500">
      <v-card width="100%" min-height="500" class="pa-10">


        <!-------------------------------------->
        <!-- First step CSV                   -->
        <!-------------------------------------->
        <div v-if="step === 0">
          <span class="display-1">Add your file</span>
          <p>Penja només les columnes:</p>
          <ol>
            <li
              v-for="column in columnsCSV"
              class="mb-3"
            ><b>{{ column.name }}</b> ({{ column.path }})

              <v-chip v-if="column.groupedWith"
                      class="rounded ml-2"
                      color="blue accent-1"
              >{{ column.groupedWith }}
              </v-chip>
            </li>
          </ol>


          <v-sheet color="grey lighten-3"
                   style="border: #256EFF dashed 1px !important; position: relative"
                   class="mt-5 text-center" min-height="150">
            <!--/*          <form style="width: 100%; height: 100%">*/-->
            <input class="hide_file" style="background-color: red" type="file" ref="file" accept="text/csv"
                   @change="previewCsv"/>
            <!--          </form>-->
            <span style="position: absolute; top: 40%; left: 43%">Drop your CSV file</span>
          </v-sheet>

          <v-alert v-if="file" text color="primary" border="left" type="success" class="mt-6">
            {{ file.name }}
          </v-alert>

          <v-card-actions class="pa-0 mt-5">
            <v-spacer></v-spacer>
            <v-btn
              :disabled="!file"
              depressed
              @click="csvToJSON"
              class="text-none px-12 shadow-sm"
              color="primary">
              Continuar
            </v-btn>
          </v-card-actions>
        </div>

        <!-------------------------------------->
        <!-- Second step CSV                  -->
        <!-------------------------------------->
        <div v-if="step === 1">
          <div class="d-flex">
            <span class="display-1">Add the SQL headers</span>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              @click="uploadCsv"
              class="text-none px-12 shadow-sm"
              color="primary">
              Upload
            </v-btn>
          </div>

          <!-------------------------------------->
          <!-- Add headers                      -->
          <!-------------------------------------->
          <v-row class="mt-10">
            <v-col v-for="(header, idx) in sqlHeaders">
              <v-text-field v-model="sqlHeaders[idx]" outlined hide-details dense></v-text-field>
              <v-select
                v-model="typeCol[idx]"
                @change="parseColumn(idx)"
                :items="['STRING', 'FLOAT', 'NUM']"
                solo
                dense
                hide-details
              ></v-select>
            </v-col>
          </v-row>

          <!-------------------------------------->
          <!-- Table preview                    -->
          <!-------------------------------------->
          <v-row no-gutters style="border-right: thin #dbdbdb solid" class="mt-4">
            <v-col v-for="(col, idx) in headers">
              <v-card height="30"
                      style="max-width: 200px"
                      flat tile color="primary" dark class="text-center text-truncate">
                {{ col }}
              </v-card>
              <div v-for="row in json[idx]" class="justify-end text-center text-truncate"
                   style="border-bottom: thin #dbdbdb solid; border-left: thin #dbdbdb solid; height: 32px; max-width: 200px"
              >
                <span class="text-truncate d-inline-block">
                  {{ row }}
                </span>
              </div>
            </v-col>
          </v-row>
        </div>


        <!-------------------------------------->
        <!-- Third step CSV                   -->
        <!-------------------------------------->
        <div v-if="step === 2">

          <v-alert text color="success" border="left" type="success" class="mt-6">
            Notes de tall uploaded!
          </v-alert>

        </div>

      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import pulseLoader from '../../components/VLoader.vue'
  import PonderacionsSearchBar from "./PonderacionsSearchBar";
import PonderacionsTop from "./PonderacionsTop";
import PonderacionsResult from "./PonderacionsResult";

export default {
  name: 'Ponderacions',
  metaInfo() {

    return {
      title: `Notes de tall i ponderacions 2022 de Catalunya`,
      meta: [
        {charset: 'utf-8'},
        {
          name: 'description', content: 'Busca la nota de tall que necessites i descobreix ' +
            'quines assignatures t\'has de presentar a la selectivitat per entrar a la carrera que dessitges.'
        },
        {name: 'viewport', content: 'width=device-width, initial-scale=1'}
      ]
    }
  },
  components: {
    PonderacionsResult,
    PonderacionsTop,
    PonderacionsSearchBar,
    pulseLoader
  },
  data() {
    return {
      columnsCSV: [
        {name: "Codi PAU i FP", path: "career_id", groupedWith: null},
        {name: "Nom del centre d'estudi i població", path: "career_name", groupedWith: null},
        {name: "Sigles Universitat", path: "career_university", groupedWith: null},
        {name: "PAU / CFGS i FP", path: "grade_pau", groupedWith: null},
        {name: "Més grans de 25 anys", path: "grade_25", groupedWith: null},
        {name: "Titulats universitaris", path: "grade_uni", groupedWith: null},
      ],
      order_id: 0,
      step: 0,
      orders: [
        {
          text: 'Relacionats',
          id: 0
        },
        {
          text: 'Nota de tall',
          id: 1
        }
      ],
      loading: false,
      addCSVDialog: false,
      searchQuery: null,
      subjects: null,
      model: -1,
      json: null,
      headers: null,
      sqlHeaders: [],
      typeCol: [],
      file: null
    }
  },
  methods: {
    async previewCsv(event) {
      this.file = event.target.files[0];

      const csv = await this.file.text();
      var lines = csv.split("\n");
      const headers = lines[0].split(";");

      headers.forEach((h, idx) => {
        if (this.columnsCSV[idx])
          this.columnsCSV[idx].groupedWith = h
      })
    },
    parseColumn(col_num) {
      for (let j = 0; j < this.json[col_num].length; j++) {
        let num = this.json[col_num][j];
        let parse = num;

        if (this.typeCol[col_num] === 'NUM') parse = parseInt(num)
        else if (this.typeCol[col_num] === 'FLOAT') parse = num ? parseFloat(num.replace(/,/g, ".")) : null
        this.json[col_num][j] = parse
      }
    },
    async csvToJSON() {
      this.step++;
      const csv = await this.file.text();
      var lines = csv.split("\n");
      this.headers = lines[0].split(";");

      this.sqlHeaders.push('career_id');
      this.sqlHeaders.push('career_name');
      this.sqlHeaders.push('career_university');
      this.sqlHeaders.push('grade_pau');
      this.sqlHeaders.push('grade_25');
      this.sqlHeaders.push('grade_uni');


      this.typeCol = new Array(this.headers.length);
      this.typeCol.fill('STRING');

      let arrays = [];
      for (let i = 0; i < this.headers.length; i++) {

        let array = [];
        for (let j = 1; j < lines.length; j++) {
          let currentline = lines[j].split(";");
          if (currentline[i])
            array.push(currentline[i].replace(/"+/g, ''));
          else
            array.push(null);
        }
        arrays.push(array);
      }
      this.json = arrays; //JSON
    },
    async uploadCsv() {
      if (this.$root.currentUser.isAdmin()) {
        try {
          this.loading = true;

          let values = [];

          for (let i = 0; i < this.json[0].length; i++) {
            const obj = {};
            for (let j = 0; j < this.json.length; j++) {
              obj[this.sqlHeaders[j]] = this.json[j][i];
            }
            values.push(obj);
          }

          await this.axios.post('/careers', {
            careers: values
          })

          // await this.axios.post('/upload-csv', {
          //   headers: this.sqlHeaders,
          //   table_name: 'careers_grade',
          //   values: values
          // })
        } finally {
          this.loading = false;
          this.step++;
        }
      }
    }
  }
}
</script>

<style scoped>

.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 70px;
}

.hide_file {
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 0;
  height: 100%;
  font-size: 24px;
  width: 100%;
  opacity: 0;
}

</style>
