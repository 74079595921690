<template>
  <div>
    <h2 class="tit font-weight-bold blue-grey--text text--darken-3 d-flex my-3">Carreres més buscades</h2>
    <!-------------------------------------->
    <!-- POPULAR CAREERS                  -->
    <!-------------------------------------->
    <v-row no-gutters>
      <v-col
        v-for="career in careers"
        :cols="$vuetify.breakpoint.smAndDown ? 6 : 4"
        class="pl-1 pr-1 pt-3"
      >
        <v-hover class="transition-linear-out-slow-in"
                 v-slot:default="{ hover }">
          <v-card @click="searchCareer(career)"
                  class="shadow-sm"
                  style="cursor: pointer"
                  :height="$vuetify.breakpoint.smAndDown ? 150 : 100">
            <v-container class="pa-5 pt-6 ma-0">
              <h3 class="tit font-weight-bold blue-grey--text text--darken-3"
                  :style="$vuetify.breakpoint.smAndDown? 'font-size: 14px' : 'font-size: 19px'"
              >
                {{career}}
              </h3>
            </v-container>
            <v-sheet v-if="hover"
                     color="amber lighten-1"
                     min-width="100%"
                     min-height="6"
                     style="position: absolute; bottom: 0; z-index: 1"
            >
            </v-sheet>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </div>
</template>

<script>

  export default {
    name: 'PonderacionsTop',

    data() {
      return {
        careers: [
          'Medicina',
          "Administració i Direcció d\'Empreses",
          'Psicologia',
          'Enginyeria Informàtica',
          'Dret',
          'Infermeria',
          'Educació Primària',
          'Enginyeria en Tecnologies Industrials',
          'Odontologia',
          'Farmàcia',
          'Veterinària',
          'Arquitectura'

        ]
      }
    },
    methods: {
      async searchCareer(career) {
        this.searchQuery = career
        this.$router.push({
          path: `/notes-de-tall`, query: {
            public: 'grade_pau',
            query: this.searchQuery
          }
        })
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      }
    },
  }
</script>

<style scoped>
</style>
